text {
  fill: #000;
  font-size: 12px;
}


text.symbol {
  fill: #000;
}

.axis text {
  font-size: 12px;
}

path.tick {
  stroke: LightBlue;
  stroke-width: 1;
}

path.volume {
  fill: #cccccc;
}

path.line {
  fill: none;
  stroke: #BF5FFF;
  stroke-width: 1;
}

path.candle {
  stroke: #000000;
}

path.candle.body {
  stroke-width: 0;
}

path.candle.up {
  fill: #00aa00;
  opacity: 0.7;
  stroke: #00aa00;
}

path.candle.down {
  fill: #ff0000;
  opacity: 0.7;
  stroke: #ff0000;
}

.close.annotation.up path {
  fill: #00AA00;
}

.extent {
  stroke: #fff;
  fill-opacity: .125;
  shape-rendering: crispEdges;
}

.crosshair {
  cursor: crosshair;
}

.crosshair path.wire {
  stroke: red;
  stroke-dasharray: 1, 1;
}

.crosshair .axisannotation path {
  fill: orange;
}

rect.pane {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.indicator path.line {
  fill: none;
  stroke-width: 1;
}

.ma-0 path.line {
  stroke: #1f77b4;
}

.ma-1 path.line {
  stroke: #aec7e8;
}

.ma-2 path.line {
  stroke: #ff7f0e;
}