/* Hide scrollbar for Chrome, Safari and Opera */
.none_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.none_scrollbar {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.half_background {
  background: linear-gradient(to top, yellow 50%, transparent 50%);
}
